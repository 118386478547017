import PageLayout from 'components/layouts/PageLayout';
import LeadsContent from 'components/features/leads/LeadsContent';

import { useGetAllLeadsQuery } from 'redux/leadApi';
import { useGetFavoritesQuery } from 'redux/favoriteApi';
import { connect } from 'react-redux';
import { RootState } from 'redux/store';
import { FilterState } from 'redux/filterReducer';
import { useGetAllUsersQuery } from 'redux/userApi';
import { getAllLocations } from 'components/features/users/display-users/TableFilterUtils';

type Props = {
  filters: FilterState;
};

const Leads = (props: Props) => {
  const { filters } = props;
  const {
    data: leads,
    error,
    isFetching
  } = useGetAllLeadsQuery(
    { leadsAge: filters.leadsAge, leadsLocationFilter: filters.leadsLocationFilter },
    { refetchOnMountOrArgChange: true }
  );
  const { data: favorites } = useGetFavoritesQuery();
  const { data: usersOriginal = [] } = useGetAllUsersQuery();

  const locations = getAllLocations(usersOriginal);

  return (
    <PageLayout>
      <LeadsContent
        leads={leads ?? []}
        locations={locations}
        favorites={favorites ?? []}
        error={error}
        isLoading={isFetching}
      />
    </PageLayout>
  );
};

const mapStateToProps = (state: RootState) => ({
  filters: state.filters
});

export default connect(mapStateToProps)(Leads);

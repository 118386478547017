import { useEffect } from 'react';
import { useGoogleLogin, CodeResponse } from '@react-oauth/google';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Header from 'components/layouts/header/Header';
import LoadingThreeDots from '../../shared/loading/LoadingThreeDots';

import { useLoginMutation } from 'redux/api';
import { RootState } from 'redux/store';

import styles from './_login.module.css';

interface Props {
  redirectPath: string;
}

export default function LoginContent(props: Props) {
  const { redirectPath } = props;
  const [login, { isLoading }] = useLoginMutation();
  const { isLoggedIn } = useSelector((state: RootState) => state.auth);

  const navigate = useNavigate();

  const googleLogin = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: async (
      credentialResponse: Omit<CodeResponse, 'error' | 'error_description' | 'error_uri'>
    ) => {
      try {
        await login({ code: credentialResponse.code }).unwrap();
      } catch (error) {
        console.log(error);
      }
    },
    onError: (errorResponse) => console.error(errorResponse)
  });

  const handleLogin = () => {
    googleLogin();
  };

  useEffect(() => {
    const path = redirectPath === '/login' || redirectPath === '/leads' ? '/' : redirectPath;
    if (isLoggedIn) {
      navigate(path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  return (
    <>
      {isLoading ? (
        <LoadingThreeDots fullScreen />
      ) : (
        <div className={styles['login-content']}>
          <Header />
          <section className={styles['login-content-banner']}>
            <h1 className={styles['login-content-banner-title']}>Welcome to Neuron!</h1>

            <button className={styles['login-content-banner-action']} onClick={handleLogin}>
              LOG IN
            </button>
          </section>
        </div>
      )}
    </>
  );
}

import { FormControl } from '@mui/material';

import { Controller, useForm } from 'react-hook-form';
import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import InformationInput from './InformationInput';
import InformationDateInput from './InformationDateInput';
import FormActionBar from '../form-actions/FormActionBar';
import FormActionButton from '../form-actions/FormActionButton';

import { useEditLeadMutation } from 'redux/leadApi';
import * as TypesLeads from 'types/leads';

import styles from './_information.module.css';
interface Props {
  lead: TypesLeads.Lead;
  isReadOnly: boolean;
}

const InformationForm = (props: Props) => {
  const { lead: defaultLead, isReadOnly } = props;

  const leadInformationSchema = object({
    companyName: string().max(64, 'Too long. max 64 characters').optional().nullable(),
    companyEmail: string()
      .email('Not a valid email')
      .max(128, 'Too long. max 128 characters')
      .optional()
      .nullable(),
    location: string().max(64, 'Too long. max 64 characters').optional().nullable(),
    role: string().max(64, 'Too long. max 64 characters').optional().nullable(),
    startDate: string().nullable(),
    endDate: string().nullable()
  });

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { isDirty }
  } = useForm<TypesLeads.Lead>({
    resolver: yupResolver(leadInformationSchema),
    defaultValues: defaultLead,
    mode: 'onChange'
  });

  const [editLead] = useEditLeadMutation();

  const handleReset = () => {
    reset(defaultLead);
  };

  const handleUpdate = async (lead: TypesLeads.Lead): Promise<void> => {
    try {
      await editLead({
        leadId: lead.leadId,
        companyName: lead.companyName,
        companyEmail: lead.companyEmail,
        location: lead.location,
        role: lead.role,
        startDate: lead.startDate,
        endDate: lead.endDate,
        lastApplicationDate: lead.lastApplicationDate
      }).unwrap();
      reset(lead);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <FormActionBar title="Information">
        {isDirty && (
          <>
            <FormActionButton variant="clear" onClick={handleReset} />
            <FormActionButton variant="save" onClick={handleSubmit(handleUpdate)} />
          </>
        )}
      </FormActionBar>

      <FormControl fullWidth>
        <div className={styles['information-row']}>
          <span className={styles['information-row-label']}>Consultant Broker</span>
          <Controller
            control={control}
            name="companyName"
            render={({ field, fieldState }) => (
              <InformationInput
                isReadOnly={isReadOnly}
                value={field.value}
                inputProps={{
                  className: styles['information-text-input']
                }}
                className={styles['information-editable-field']}
                onChange={(companyName: string) => {
                  field.onChange(companyName);
                }}
                error={fieldState.error}
                isDirty={fieldState.isDirty}
              />
            )}
          />
        </div>
        <div className={styles['information-row']}>
          <span className={styles['information-row-label']}>Mail</span>
          <Controller
            control={control}
            name="companyEmail"
            render={({ field, fieldState }) => (
              <InformationInput
                isReadOnly={isReadOnly}
                value={field.value}
                inputProps={{
                  className: styles['information-text-input']
                }}
                className={styles['information-editable-field']}
                onChange={(mail: string) => {
                  field.onChange(mail);
                }}
                error={fieldState.error}
                isDirty={fieldState.isDirty}
              />
            )}
          />
        </div>
        <div className={styles['information-row']}>
          <span className={styles['information-row-label']}>Role</span>
          <Controller
            control={control}
            name="role"
            render={({ field, fieldState }) => (
              <InformationInput
                isReadOnly={isReadOnly}
                value={field.value}
                inputProps={{
                  className: styles['information-text-input']
                }}
                className={styles['information-editable-field']}
                onChange={(role: string) => {
                  field.onChange(role);
                }}
                error={fieldState.error}
                isDirty={fieldState.isDirty}
              />
            )}
          />
        </div>
        <div className={styles['information-row']}>
          <span className={styles['information-row-label']}>Location</span>
          <Controller
            control={control}
            name="location"
            render={({ field, fieldState }) => (
              <InformationInput
                isReadOnly={isReadOnly}
                value={field.value}
                inputProps={{
                  className: styles['information-text-input']
                }}
                className={styles['information-editable-field']}
                onChange={(location: string) => {
                  field.onChange(location);
                }}
                error={fieldState.error}
                isDirty={fieldState.isDirty}
              />
            )}
          />
        </div>
        <div className={styles['information-row']}>
          <span className={styles['information-row-label']}>Due date</span>
          <Controller
            control={control}
            name="lastApplicationDate"
            render={({ field }) => (
              <InformationDateInput
                isReadOnly={isReadOnly}
                value={field.value}
                maxValue={watch().endDate}
                inputProps={{
                  className: styles['information-text-input']
                }}
                className={styles['information-editable-field']}
                onChange={(endDate: string) => {
                  field.onChange(endDate);
                }}
              />
            )}
          />
        </div>
        <div className={styles['information-row']}>
          <span className={styles['information-row-label']}>Start date</span>
          <Controller
            control={control}
            name="startDate"
            render={({ field }) => (
              <InformationDateInput
                isReadOnly={isReadOnly}
                value={field.value}
                maxValue={watch().endDate}
                inputProps={{
                  className: styles['information-text-input']
                }}
                className={styles['information-editable-field']}
                onChange={(endDate: string) => {
                  field.onChange(endDate);
                }}
              />
            )}
          />
        </div>
        <div className={styles['information-row']}>
          <span className={styles['information-row-label']}>End date</span>
          <Controller
            control={control}
            name="endDate"
            render={({ field }) => (
              <InformationDateInput
                isReadOnly={isReadOnly}
                value={field.value}
                minValue={watch().startDate}
                inputProps={{
                  className: styles['information-text-input']
                }}
                className={styles['information-editable-field']}
                onChange={(endDate: string) => {
                  field.onChange(endDate);
                }}
              />
            )}
          />
        </div>
      </FormControl>
    </>
  );
};

export default InformationForm;

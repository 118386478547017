import { useEffect, useMemo, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import {
  HeaderGroup,
  useFlexLayout,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable
} from 'react-table';
import { Paper, TableContainer } from '@mui/material';
import sort from '../../../../assets/icons/sort.png';
import sortdesc from '../../../../assets/icons/sortdesc.png';
import sortasc from '../../../../assets/icons/sortasc.png';

import { Columns } from './Columns';
import UsersTable from './UsersTable';
import UserOverview from './UserOverview';
import ActionBar from '../action-bar/ActionBar';
import useWindowHeight from 'components/shared/hooks/WindowHeight';
import { getRowCountForHeight } from 'components/shared/functions/_functionsTable';
import { FilterState } from 'redux/filterReducer';
import * as TypesLeads from 'types/leads';

import styles from './_displayUsers.module.css';
import { getAllLocations, getAllSkills, handleFiltering } from './TableFilterUtils';
import useRowSelectionConsultants from './UseRowSelectionConsultants';
import ConsultantLeadsOverview from './display-users-multiple-matching-leads/ConsultantLeadsOverview';
import { RootState } from 'redux/store';
import { setConsultantPageState, setConsultantIsSwitched } from 'redux/navigationReducer';

export const USERS_TABLE_ROW_HEIGHT = 45;
const USERS_TABLE_FOOTER_HEIGHT = 50;
const USERS_TABLE_HEAD_HEIGHT = 60;

interface Props {
  isError?: boolean;
  isLoading: boolean;
  users: TypesLeads.User[];
  filters: FilterState;
  matchingLoaded: boolean;
  hiddenColumns: string[];
  returnToMultipleSelected: boolean;
}

function UsersContent(props: Props) {
  const {
    users,
    isError,
    isLoading,
    filters,
    matchingLoaded,
    hiddenColumns,
    returnToMultipleSelected
  } = props;
  const { consultantsLocationFilter, showSelectedUsersFilter, skillsFilter, usersFilter } = filters;
  const locationObject = useLocation();
  const [data, setData] = useState(users);
  const [searchParams] = useSearchParams();
  const isForLead = searchParams.has('forLead');
  const columns = useMemo(() => Columns(isForLead), [isForLead]);
  const dispatch = useDispatch();
  const { consultantPageState } = useSelector((state: RootState) => state.navigation);
  const [allLocations, setAllLocations] = useState<string[]>([]);
  const [allSkills, setAllSkills] = useState<string[]>([]);

  const [activeCheckedUserIndex, setActiveCheckedUserIndex] = useState(-1);

  /* Renderar om sidan då skärmens höjd förändras. */
  useWindowHeight();

  const {
    selectedFlatRows,
    headerGroups,
    getTableProps,
    getTableBodyProps,
    prepareRow,
    setHiddenColumns,
    canPreviousPage,
    canNextPage,
    page,
    pageOptions,
    pageCount,
    nextPage,
    previousPage,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize, selectedRowIds }
  } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: hiddenColumns,
        pageIndex: 0,
        pageSize: 10,
        selectedRowIds: consultantPageState.selectedUserIds as Record<string, boolean>
      },
      autoResetPage: false,
      autoResetSelectedRows: false,
      getRowId: (row) => String(row.userId)
    },
    useFlexLayout,
    useSortBy,
    usePagination,
    useRowSelect,
    useRowSelectionConsultants()
  );

  const [isLeadsView, setIsLeadsView] = useState<boolean>(
    selectedFlatRows.length > 0
      ? returnToMultipleSelected || consultantPageState.matchingLeadsMultipleToggle
      : false
  );

  useEffect(() => {
    setHiddenColumns(hiddenColumns);
  }, [hiddenColumns, setHiddenColumns]);

  useEffect(() => {
    if (!isLoading) setData(users);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isForLead, users.length]);

  const getDefaultSelectedUser = () => {
    const locationUserId = locationObject.state?.userId;
    if (locationUserId) {
      return users.find((item) => item.userId === locationUserId);
    }
    return users.length > 0 ? users[0] : undefined;
  };

  // TODO: remove cast when child components accept undefined (we fooled TS previously as well)
  const defaultSelectedUser = getDefaultSelectedUser() as TypesLeads.User;
  const [previousDefaultSelectedUser, setPreviousDefaultSelectedUser] = useState<TypesLeads.User>();
  const [selectedUser, setSelectedUser] = useState<TypesLeads.User>(defaultSelectedUser);
  // The default selected user always overrides the current selected user
  if (previousDefaultSelectedUser?.userId !== defaultSelectedUser?.userId) {
    setSelectedUser(defaultSelectedUser);
    setPreviousDefaultSelectedUser(defaultSelectedUser);
  }

  const onActiveCheckedUserIndexChange = (index: number) => {
    const activeCheckedUser =
      selectedFlatRows.length > 0 ? selectedFlatRows[index]?.original : undefined;
    if (activeCheckedUser) {
      setSelectedUser(activeCheckedUser);
    }
    setActiveCheckedUserIndex(index);
  };

  // Always navigate to the page where the selected user is located when selected user is changed
  const selectedUserIndex = users.findIndex((item) => item.userId === selectedUser?.userId);
  useEffect(() => {
    const userPageIndex = Math.floor(selectedUserIndex / pageSize);
    gotoPage(userPageIndex);
  }, [gotoPage, pageSize, selectedUserIndex]);

  useEffect(() => {
    const selectedUserIdFromLocalStorage = localStorage.getItem('selectedUserId') || '0';

    if (selectedUserIdFromLocalStorage) {
      const convertedSelectedUserFromLocalStorage = Number(selectedUserIdFromLocalStorage);
      if (convertedSelectedUserFromLocalStorage !== selectedUser?.userId) {
        dispatch(setConsultantIsSwitched(true));
        localStorage.setItem('matchingLeadsTableIndex', String(0));
        localStorage.setItem('selectedUserId', selectedUser?.userId.toString());
      } else {
        dispatch(setConsultantIsSwitched(false));
      }
    }
  }, [dispatch, selectedUser]);

  useEffect(() => {
    setData(
      handleFiltering(
        showSelectedUsersFilter,
        selectedFlatRows,
        selectedRowIds,
        users,
        consultantsLocationFilter,
        usersFilter,
        skillsFilter
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSelectedUsersFilter, consultantsLocationFilter, usersFilter, skillsFilter]);

  useEffect(() => {
    if (users.length) {
      setAllLocations(getAllLocations(users));
      setAllSkills(getAllSkills(users));
    }
  }, [users]);

  useEffect(() => {
    if (pageIndex >= pageCount) {
      gotoPage(Math.max(0, pageCount - 1));
    }
  }, [gotoPage, pageCount, pageIndex]);

  const handleTableHeight = (element: HTMLDivElement) => {
    if (element?.clientHeight) {
      /* Remove some height for table header and footer*/
      let rowCount = getRowCountForHeight(
        element.clientHeight - (USERS_TABLE_FOOTER_HEIGHT + USERS_TABLE_HEAD_HEIGHT),
        USERS_TABLE_ROW_HEIGHT
      );

      rowCount = Math.max(1, rowCount);

      rowCount = Math.max(1, rowCount);

      if (pageSize != rowCount) {
        setPageSize(rowCount);
      }
    }
  };

  useEffect(() => {
    dispatch(
      setConsultantPageState({
        selectedUserIds: selectedRowIds,
        matchingLeadsMultipleToggle: false
      })
    );
  }, [selectedRowIds, dispatch]);

  const paginationData: TypesLeads.Pagination = {
    pageIndex: pageIndex,
    pageOptionsLength: pageOptions.length,
    nextPage: nextPage,
    previousPage: previousPage,
    gotoPage: gotoPage,
    pageCount: pageCount,
    canNextPage: canNextPage,
    canPreviousPage: canPreviousPage
  };

  const showSortingArrow = (column: HeaderGroup<TypesLeads.User>) => {
    if (column.isSorted) {
      if (column.isSortedDesc) return sortdesc;
      return sortasc;
    }
    return sort;
  };

  return (
    <div className={styles['users-page-container']}>
      <ActionBar
        allLocations={allLocations}
        allSkills={allSkills}
        isLeadsView={isLeadsView}
        setIsLeadsView={setIsLeadsView}
        selectedFlatRows={selectedFlatRows}
        isForLead={isForLead}
        showApplyHereLink={false}
      />
      <div style={{ display: 'flex', flexDirection: 'row', gap: '0.5rem' }}>
        <div className={styles['users-table-wrapper']}>
          <div style={{ position: 'sticky', top: '0.5rem' }}>
            <TableContainer
              component={Paper}
              className={styles['users-table-container']}
              ref={handleTableHeight}>
              <div className={styles['users-table-head']}>
                {headerGroups.map((headerGroup: HeaderGroup<TypesLeads.User>, index: number) => (
                  <div
                    {...headerGroup.getHeaderGroupProps()}
                    key={index}
                    className={styles['users-table-head-row']}>
                    {headerGroup.headers.map((column: HeaderGroup<TypesLeads.User>) => {
                      if (column.id === 'selection')
                        return (
                          <div key={column.id} style={{ width: '40px', paddingLeft: '9px' }}>
                            {column.render('Header')}
                          </div>
                        );
                      return (
                        <div
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                          key={column.id}
                          className={
                            column.Header === 'Name'
                              ? styles['users-table-head-cell-name']
                              : column.Header === 'Assigned'
                              ? styles['users-table-head-cell-assigned']
                              : styles['users-table-head-cell']
                          }>
                          {column.render('Header')}
                          {column.canSort && (
                            <img
                              src={showSortingArrow(column)}
                              className={styles['users-table-head-cell-sorting-arrow']}
                            />
                          )}
                        </div>
                      );
                    })}
                  </div>
                ))}
              </div>
              <UsersTable
                isError={isError}
                isLoading={isLoading}
                rows={page}
                totalUsers={data.length}
                getTableProps={getTableProps}
                getTableBodyProps={getTableBodyProps}
                prepareRow={prepareRow}
                isFilter={filters.usersFilter.length > 0}
                paginationData={paginationData}
                pageSize={pageSize}
                setSelectedUser={setSelectedUser}
                selectedUser={selectedUser}
                setActiveCheckedUserIndex={onActiveCheckedUserIndexChange}
              />
            </TableContainer>
          </div>
        </div>
        {isLeadsView ? (
          <ConsultantLeadsOverview users={selectedFlatRows} singleUser={selectedUser} />
        ) : (
          <UserOverview
            user={selectedUser}
            isLeadsView={isLeadsView}
            isLoading={isLoading}
            matchingLoaded={matchingLoaded}
            isForLead={isForLead}
            hasReturned={locationObject.state?.hasReturned}
            myLeads={locationObject.state?.myLeads}
            userId={locationObject.state?.userId}
            selectedFlatRows={selectedFlatRows}
            activeCheckedUserIndex={activeCheckedUserIndex}
            setActiveCheckedUserIndex={onActiveCheckedUserIndexChange}
          />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state: { filters: FilterState }) => ({
  filters: state.filters
});

export default connect(mapStateToProps)(UsersContent);

import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import UserInformation from './UserInformation';
import { ReactComponent as Logo } from 'assets/icons/logo.svg';

import { RootState } from 'redux/store';
import * as TypesRedux from 'types/redux';

import styles from './_header.module.css';
import Navigation from '../navigation/Navigation';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import React from 'react';

const Header = () => {
  const { isLoggedIn, user }: TypesRedux.InitialStateAuth = useSelector(
    (state: RootState) => state.auth
  );

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: '#37506a'
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#37506a',
      color: '#FFFFFF',
      padding: '10px 30px',
      fontSize: '14px',
      border: '1px solid #dadde9'
    }
  }));

  return (
    <header className={styles['header']}>
      {!isLoggedIn ? (
        <Link to="/">
          <Logo height={45} />
        </Link>
      ) : (
        <>
          <div className={styles['header-section']}>
            <Link to="/">
              <Logo height={45} />
            </Link>
            <Navigation />
          </div>
          <div className={styles['header-section']}>
            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography component="div">
                    <h3 style={{ margin: '5px 0px' }}>Please contact us if</h3>
                    <ul style={{ listStyleType: 'disc' }}>
                      <li>You have questions.</li>
                      <li>You have improvement ideas.</li>
                      <li>You have found a bug.</li>
                    </ul>
                  </Typography>
                </React.Fragment>
              }
              arrow>
              <Link
                to="https://mail.google.com/mail/?view=cm&fs=1&to=neuron-feedback-aaaammrmlrnmdtdvacbr7vs7se@softhouse.slack.com&su=Feedback"
                target="_blank"
                className={styles['feedback-link']}>
                Feedback
              </Link>
            </HtmlTooltip>
            <Link
              to="/consultants"
              state={{ myLeads: true, userId: user?.userId }}
              className={styles['my-leads-link']}>
              My Matching Leads
            </Link>
            <UserInformation />
          </div>
        </>
      )}
    </header>
  );
};

export default Header;

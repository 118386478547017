import { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import {
  Column,
  HeaderGroup,
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useTable
} from 'react-table';
import { Paper, TableContainer } from '@mui/material';

import { Columns } from './Columns';
import LeadsForUserTable from './LeadsForUserTable';
import LeadsForUserLeadDetails from './LeadsForUserLeadDetails';
// import ActionBar from '../action-bar/ActionBar';
import useWindowHeight from 'components/shared/hooks/WindowHeight';
import { getRowCountForHeight } from 'components/shared/functions/_functionsTable';
import { FilterState } from 'redux/filterReducer';
// import { globalUsersFilter, tableReducer } from './_functionsDisplayLeads';
import * as TypesLeads from 'types/leads';

import styles from './_leadsForUser.module.css';
import { tableReducer } from './_functionsDisplayLeads';

export const USERS_TABLE_ROW_HEIGHT = 45;
const USERS_TABLE_FOOTER_HEIGHT = 50;
const USERS_TABLE_HEAD_HEIGHT = 60;

interface Props {
  isError?: boolean;
  isLoading: boolean;
  leads: TypesLeads.Lead[];
  filters: FilterState;
  hiddenColumns: string[];
}

function LeadsForUserContent(props: Props) {
  const { leads, isError, isLoading, filters, hiddenColumns } = props;

  const data = useMemo(() => leads as TypesLeads.Lead[], [leads]);
  const columns = useMemo(() => Columns as Column<TypesLeads.Lead>[], []);

  /* Renderar om sidan då skärmens höjd förändras. */
  useWindowHeight();

  const {
    selectedFlatRows,
    headerGroups,
    getTableProps,
    getTableBodyProps,
    prepareRow,
    setGlobalFilter,
    setHiddenColumns,
    canPreviousPage,
    canNextPage,
    page,
    pageOptions,
    pageCount,
    nextPage,
    previousPage,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      //   globalFilter: globalUsersFilter,
      stateReducer: tableReducer,
      initialState: {
        hiddenColumns: hiddenColumns,
        pageIndex: 0,
        pageSize: 10,
        selectedRowIds: { 0: true }
      },
      autoResetPage: false
    },
    useFlexLayout,
    useGlobalFilter,
    usePagination,
    useRowSelect
  );
  useEffect(() => {
    setGlobalFilter(filters.usersFilter);
  }, [filters.usersFilter, setGlobalFilter]);

  useEffect(() => {
    if (pageIndex >= pageCount) {
      gotoPage(Math.max(0, pageCount - 1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageCount]);

  const handleTableHeight = (element: HTMLDivElement) => {
    if (element?.clientHeight) {
      /* Remove some height for table header and footer*/
      let rowCount = getRowCountForHeight(
        element.clientHeight - (USERS_TABLE_FOOTER_HEIGHT + USERS_TABLE_HEAD_HEIGHT),
        USERS_TABLE_ROW_HEIGHT
      );

      rowCount = Math.max(1, rowCount);

      if (pageSize != rowCount) {
        setPageSize(rowCount);
      }
    }
  };

  useEffect(() => {
    setHiddenColumns(hiddenColumns);
  }, [hiddenColumns, setHiddenColumns]);

  const paginationData: TypesLeads.Pagination = {
    pageIndex: pageIndex,
    pageOptionsLength: pageOptions.length,
    nextPage: nextPage,
    previousPage: previousPage,
    gotoPage: gotoPage,
    pageCount: pageCount,
    canNextPage: canNextPage,
    canPreviousPage: canPreviousPage
  };

  return (
    <>
      {/*<ActionBar />*/}

      <TableContainer
        component={Paper}
        className={styles['users-table-container']}
        ref={handleTableHeight}>
        <div className={styles['users-table-head']}>
          {headerGroups.map((headerGroup: HeaderGroup<TypesLeads.Lead>, index: number) => (
            <div
              {...headerGroup.getHeaderGroupProps()}
              key={index}
              className={styles['users-table-head-row']}>
              {headerGroup.headers.map((column: HeaderGroup<TypesLeads.Lead>) => (
                <div
                  {...column.getHeaderProps()}
                  key={column.id}
                  className={styles['users-table-head-cell']}>
                  {column.render('Header')}
                </div>
              ))}
            </div>
          ))}
        </div>

        <div className={styles['users-table-content']}>
          <LeadsForUserTable
            isError={isError}
            isLoading={isLoading}
            rows={page}
            getTableProps={getTableProps}
            getTableBodyProps={getTableBodyProps}
            prepareRow={prepareRow}
            selectedRow={selectedFlatRows[0] || page[0]}
            isFilter={filters.usersFilter.length > 0}
            paginationData={paginationData}
            pageSize={pageSize}
          />
          <LeadsForUserLeadDetails lead={selectedFlatRows[0]?.original} />
        </div>
      </TableContainer>
    </>
  );
}

const mapStateToProps = (state: { filters: FilterState }) => ({
  filters: state.filters
});

export default connect(mapStateToProps)(LeadsForUserContent);

import React, {
  useState,
  useRef,
  KeyboardEvent,
  useEffect,
  HTMLAttributes,
  SyntheticEvent
} from 'react';
import { Autocomplete, Box, TextField } from '@mui/material';

import LeadStatus from 'components/features/leads/leads-table/lead-status/LeadStatus';
import * as TypesLeads from 'types/leads';

import styles from './_status.module.css';
import { getChosenOption, Option } from '.';
import DropDownArrow from '../../../../assets/icons/ArrowDropDownFilled.png';

interface Props {
  type: 'answered' | 'result' | 'irrelevant';
  selected: TypesLeads.Status;
  isReadOnly?: boolean;
  onChange: (status: TypesLeads.Status) => void;
}

const answeredOptions: Option[] = [
  { id: 0, label: 'Answered', color: 'var(--status-color-answered)' },
  { id: 1, label: 'Unanswered', color: 'var(--status-color-default)' }
];

const irrelevantOptions: Option[] = [
  { id: 0, label: 'No', color: 'var(--status-color-irrelevant)' },
  { id: 1, label: 'Yes', color: 'var(--status-color-relevant)' }
];

const resultOptions: Option[] = [
  { id: 0, label: 'Won', color: 'var(--status-color-won)' },
  { id: 1, label: 'Lost', color: 'var(--status-color-lost)' },
  { id: 2, label: 'None', color: 'var(--status-color-none)' }
];

const StatusInput = (props: Props) => {
  const { type, selected, isReadOnly, onChange } = props;

  const [options, setOptions] = useState<Option[]>([]);
  const [selectedOption, setSelectedOption] = useState<Option>();

  const inputRef = useRef<HTMLInputElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const handleOnChange = (event: SyntheticEvent<Element, Event>, option: Option) => {
    const chosenOption = getChosenOption(option);
    if (chosenOption !== undefined) onChange({ value: chosenOption });
  };

  const handleOnKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' || event.key === 'Escape') {
      setIsEditing(false);
    }
  };

  const setOptionsBasedOnType = () => {
    switch (type) {
      case 'answered':
        setOptions(answeredOptions);
        if (selected.value) {
          setSelectedOption(answeredOptions[0]);
        } else {
          setSelectedOption(answeredOptions[1]);
        }
        break;
      case 'result':
        setOptions(resultOptions);
        if (selected.value === true) {
          setSelectedOption(resultOptions[0]);
        } else if (selected.value === false) {
          setSelectedOption(resultOptions[1]);
        } else {
          setSelectedOption(resultOptions[2]);
        }
        break;
      case 'irrelevant':
        setOptions(irrelevantOptions);
        if (selected.value) {
          setSelectedOption(irrelevantOptions[0]);
        } else {
          setSelectedOption(irrelevantOptions[1]);
        }
        break;
      default:
        break;
    }
  };

  const getSelectedColor = () => {
    switch (selectedOption?.label) {
      case 'Answered':
        return 'var(--status-color-answered)';
      case 'Won':
        return 'var(--status-color-won)';
      case 'Lost':
        return 'var(--status-color-lost)';
      case 'None':
        return 'var(--status-color-none)';
      case 'Yes':
        return 'var(--status-color-relevant)';
      case 'No':
        return 'var(--status-color-irrelevant)';
      default:
        return 'var(--status-color-default)';
    }
  };

  const checkIfFocusInput = () => {
    if (wrapperRef.current) {
      inputRef.current?.focus();
    }
  };

  const renderOptions = (props: HTMLAttributes<HTMLLIElement>, option: Option) => {
    return (
      <Box component="li" {...props}>
        <LeadStatus displayName={option.label} color={option.color} />
      </Box>
    );
  };

  useEffect(() => {
    checkIfFocusInput();
  }, [isEditing, isReadOnly]);

  useEffect(() => {
    setOptionsBasedOnType();
    setIsEditing(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, isReadOnly]);

  const renderInput = () => {
    return (
      <Autocomplete
        options={options}
        autoComplete
        multiple={false}
        open={isEditing}
        disableCloseOnSelect={false}
        onChange={handleOnChange}
        disableClearable
        ref={wrapperRef}
        className={styles['status-option-wrapper']}
        onKeyDown={handleOnKeyDown}
        onBlur={() => setIsEditing(false)}
        renderOption={renderOptions}
        renderInput={(params) => (
          <TextField
            {...params}
            value={selectedOption?.label}
            inputRef={inputRef}
            InputProps={{
              ...params.InputProps,
              style: {
                fontSize: 'var(--font-size-s)',
                padding: '0 0.5rem',
                caretColor: 'white'
              },
              startAdornment: (
                <span
                  style={{
                    width: '15px',
                    height: '15px',
                    borderRadius: '30px',
                    backgroundColor: selectedOption?.color
                  }}></span>
              )
            }}
            inputProps={{
              ...params.inputProps
            }}
            className={styles['status-option-textfield']}
            placeholder={selectedOption?.label}
          />
        )}
      />
    );
  };

  return (
    <div className={`${styles['status']}`} onClick={() => setIsEditing(true)}>
      {isEditing && !isReadOnly ? (
        renderInput()
      ) : (
        <span
          className={`${
            !isReadOnly
              ? styles['status-text-element-status']
              : styles['status-text-element-status-read-only']
          }`}>
          <LeadStatus displayName={selectedOption?.label} color={getSelectedColor()} />
          <img
            src={DropDownArrow}
            style={{ position: 'absolute', right: '0.95rem', width: '10px', height: '5px' }}
          />
        </span>
      )}
    </div>
  );
};

export default StatusInput;

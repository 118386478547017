import { HeaderGroup, Row, TablePropGetter } from 'react-table';
import { Table, TableHead, TableRow, TableCell, TableBody, TableBodyProps } from '@mui/material';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SerializedError } from '@reduxjs/toolkit';

import LeadsTableRow from './LeadsTableRow';
import LoadingThreeDots from 'components/shared/loading/LoadingThreeDots';
import ErrorMessage from 'components/shared/error/ErrorMessage';
import { LEADS_TABLE_HEAD_HEIGHT, LEADS_TABLE_ROW_HEIGHT } from './_functionsDisplayLeads';
import * as TypesLeads from 'types/leads';
import sort from '../../../../assets/icons/sort.png';
import sortdesc from '../../../../assets/icons/sortdesc.png';
import sortasc from '../../../../assets/icons/sortasc.png';

import styles from './_displayLeads.module.css';

interface Props {
  error?: FetchBaseQueryError | SerializedError;
  isLoading: boolean;
  headerGroups: HeaderGroup<TypesLeads.Lead>[];
  rows: Row<TypesLeads.Lead>[];
  getTableProps: () => TablePropGetter<TypesLeads.Lead>;
  getTableBodyProps: () => TableBodyProps;
  prepareRow: (row: Row<TypesLeads.Lead>) => void;
  pageSize: number;
}

const LeadsTable = (props: Props) => {
  const {
    isLoading,
    error,
    headerGroups,
    rows,
    getTableProps,
    getTableBodyProps,
    prepareRow,
    pageSize
  } = props;

  const showSortingArrow = (column) => {
    if (column.isSorted) {
      if (column.isSortedDesc) return sortdesc;
      return sortasc;
    }
    return sort;
  };

  return (
    <div
      style={{ height: `${pageSize * LEADS_TABLE_ROW_HEIGHT + LEADS_TABLE_HEAD_HEIGHT - 40}px` }}>
      <Table {...getTableProps()} className={styles['leads-table']}>
        <TableHead className={styles['leads-table-head']}>
          {headerGroups.map((headerGroup: HeaderGroup<TypesLeads.Lead>, index: number) => (
            <TableRow {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column: HeaderGroup<TypesLeads.Lead>) => (
                <TableCell
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  key={column.id}
                  sx={{
                    color: '#FFFFFF'
                  }}
                  className={
                    column.Header === 'Status'
                      ? styles['leads-table-head-cell-status']
                      : styles['leads-table-head-cell']
                  }>
                  {column.render('Header')}
                  {column.id !== 'selection' && column.id !== 'findCompetenceIcon' && (
                    <img
                      src={showSortingArrow(column)}
                      className={styles['leads-table-head-cell-sorting-arrow']}
                    />
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>

        <TableBody {...getTableBodyProps()} className={styles['leads-table-body']}>
          {!isLoading && !error
            ? rows.map((row: Row<TypesLeads.Lead>) => {
                prepareRow(row);
                row.getRowProps();
                return <LeadsTableRow row={row} key={row.id} />;
              })
            : null}
        </TableBody>
      </Table>
      <div className={styles['leads-table-error-loading-container']}>
        {isLoading && error ? <ErrorMessage /> : null}
        {isLoading && !error ? <LoadingThreeDots /> : null}
      </div>
    </div>
  );
};

export default LeadsTable;

import { Row } from 'react-table';
import * as TypesLeads from 'types/leads';
import { getDifferenceInDays } from 'utils/date';
import { capitalize } from 'utils/string';

export function getTableData(
  leads: TypesLeads.Lead[],
  favorites: TypesLeads.Favorite[],
  userId?: number
): TypesLeads.Lead[] {
  return leads.map((lead: TypesLeads.Lead) => {
    const favorite = favorites.filter((favorite: TypesLeads.Favorite) => {
      if (favorite.leadId === lead.leadId && favorite.userId === userId) {
        return favorite;
      }
    })[0];

    return {
      ...lead,
      isFavorite: Boolean(favorite)
    };
  });
}

const dueDateTimeFormat = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });
export function getFormattedDueDate(dueDateOriginal: string | null | undefined): string {
  if (!dueDateOriginal) {
    return '';
  }
  const now = new Date();
  const days = getDifferenceInDays(new Date(dueDateOriginal), now);
  if (days < 0) {
    return dueDateOriginal;
  }
  return capitalize(dueDateTimeFormat.format(days, 'days'));
}

export function getFormattedCreateTime(createTimeOriginal: string): string {
  // Create a Date object for the current time
  const now = new Date();

  // Parse the lead's createTime string into a Date object
  const createTime = new Date(createTimeOriginal);

  // Calculate the difference in milliseconds
  const timeDiff = now.getTime() - createTime.getTime();

  // Check if the lead is less than 5 minutes old
  const leadIsLessThan5MinutesOld = timeDiff < 5 * 60 * 1000; // Five minutes in milliseconds

  const year = createTime.getFullYear();
  const month = String(createTime.getMonth() + 1).padStart(2, '0'); // Adding 1 because getMonth() returns zero-based month
  const day = String(createTime.getDate()).padStart(2, '0');

  const formattedDateTime = `${year}-${month}-${day}`;

  if (leadIsLessThan5MinutesOld) {
    return '< 5min';
  } else {
    return formattedDateTime;
  }
}

export function sortByDueDate(a: Row<TypesLeads.Lead>, b: Row<TypesLeads.Lead>) {
  const now = new Date();

  const aDueDays = a.original.lastApplicationDate
    ? getDifferenceInDays(new Date(a.original.lastApplicationDate), now)
    : null;
  const bDueDays = b.original.lastApplicationDate
    ? getDifferenceInDays(new Date(b.original.lastApplicationDate), now)
    : null;

  // Case: Both have no due date
  if (aDueDays === null && bDueDays === null) return 0;

  // Case: One has no due date
  if (aDueDays === null) return 1;
  if (bDueDays === null) return -1;

  // Case: Both are in the future or today
  if (aDueDays >= 0 && bDueDays >= 0) {
    return aDueDays < bDueDays ? -1 : aDueDays > bDueDays ? 1 : 0;
  }

  // Case: Both are in the past
  if (aDueDays < 0 && bDueDays < 0) {
    return aDueDays > bDueDays ? -1 : aDueDays < bDueDays ? 1 : 0;
  }

  // Case: One is in the past, one is in the future or today
  return aDueDays < 0 ? 1 : -1;
}

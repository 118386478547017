import { useCallback, useEffect, useState } from 'react';

import ViewAllLess from 'components/shared/view-all-less/ViewAllLess';

import { getMatchingSkills, getMissingSkills } from '../users/display-users/_functionsDisplayUsers';
import { getLimitedList } from 'components/shared/view-all-less/_functionsViewAllLess';
import * as TypesLeads from 'types/leads';

import styles from './_leadsForUser.module.css';
import SkillsList from '../users/display-users/SkillsList';
import { useGetUserQuery } from 'redux/userApi';

interface Props {
  lead?: TypesLeads.Lead;
  isMatching: boolean;
}

const UserSkills = (props: Props) => {
  const { lead } = props;

  const DEFAULT_SKILLS_NUMBER = 10;

  const { data: user } = useGetUserQuery(lead?.competenceStatus?.userId ?? 0);

  const matchingSkills = getMatchingSkills(user?.tags || [], lead?.tags || []);
  const missingSkills = getMissingSkills(lead?.tags || [], matchingSkills);

  const [shouldDisplayAllMatchingSkills, setShouldDisplayAllMatchingSkills] =
    useState<boolean>(false);
  const [shouldDisplayAllMissingSkills, setShouldDisplayAllMissingSkills] =
    useState<boolean>(false);

  useEffect(() => {
    setShouldDisplayAllMatchingSkills(false);
    setShouldDisplayAllMissingSkills(false);
  }, [user]);

  const displaySkillsIfMatching = useCallback(() => {
    return (
      <>
        <span className={styles['users-table-container-information-title']}>Matching skills</span>
        <SkillsList
          skills={getLimitedList(
            shouldDisplayAllMatchingSkills,
            DEFAULT_SKILLS_NUMBER,
            matchingSkills || []
          )}
          shouldDisplayAll={shouldDisplayAllMatchingSkills}
          limitNumber={DEFAULT_SKILLS_NUMBER}
          data={matchingSkills as TypesLeads.Tag[]}
          onClick={() => setShouldDisplayAllMatchingSkills(!shouldDisplayAllMatchingSkills)}
        />
        <ViewAllLess
          shouldDisplayAll={shouldDisplayAllMatchingSkills}
          limitNumber={DEFAULT_SKILLS_NUMBER}
          data={matchingSkills as TypesLeads.Tag[]}
          onClick={() => setShouldDisplayAllMatchingSkills(!shouldDisplayAllMatchingSkills)}
        />

        <span className={styles['users-table-container-information-title']}>Missing skills</span>
        <SkillsList
          skills={getLimitedList(
            shouldDisplayAllMissingSkills,
            DEFAULT_SKILLS_NUMBER,
            missingSkills || []
          )}
          shouldDisplayAll={shouldDisplayAllMatchingSkills}
          limitNumber={DEFAULT_SKILLS_NUMBER}
          data={matchingSkills as TypesLeads.Tag[]}
          onClick={() => setShouldDisplayAllMatchingSkills(!shouldDisplayAllMatchingSkills)}
        />
        <ViewAllLess
          shouldDisplayAll={shouldDisplayAllMissingSkills}
          limitNumber={DEFAULT_SKILLS_NUMBER}
          data={missingSkills as TypesLeads.Tag[]}
          onClick={() => setShouldDisplayAllMissingSkills(!shouldDisplayAllMissingSkills)}
        />
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lead, user, shouldDisplayAllMatchingSkills, shouldDisplayAllMissingSkills]);

  return <>{displaySkillsIfMatching()}</>;
};

export default UserSkills;

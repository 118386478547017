import React, { Fragment } from 'react';
import { Box } from '@mui/material';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import { Row } from 'react-table';

import * as TypesLeads from 'types/leads';
import styles from './_displayUsers.module.css';
import UserDetails from './single-user/UserDetails';
import LoadingThreeDots from 'components/shared/loading/LoadingThreeDots';
interface Props {
  user?: TypesLeads.User;
  isLoading?: boolean;
  isLeadsView?: boolean;
  matchingLoaded?: boolean;
  hasReturned?: boolean;
  myLeads: boolean;
  userId?: string;
  selectedFlatRows: Row<TypesLeads.User>[];
  activeCheckedUserIndex: number;
  setActiveCheckedUserIndex: (index: number) => void;
  isForLead: boolean;
}

function UserOverview(props: Props) {
  const {
    user,
    matchingLoaded,
    isLeadsView,
    selectedFlatRows,
    activeCheckedUserIndex,
    setActiveCheckedUserIndex
  } = props;

  if (user == null)
    return <Box component="div" className={styles['users-table-container-information']} />;

  const updateDisplaySelected = (direction: string) => {
    if (activeCheckedUserIndex === -1) {
      setActiveCheckedUserIndex(0);
    } else if (direction === 'up') {
      activeCheckedUserIndex == selectedFlatRows.length - 1
        ? setActiveCheckedUserIndex(0)
        : setActiveCheckedUserIndex(activeCheckedUserIndex + 1);
    } else if (direction === 'down') {
      activeCheckedUserIndex == 0
        ? setActiveCheckedUserIndex(selectedFlatRows.length - 1)
        : setActiveCheckedUserIndex(activeCheckedUserIndex - 1);
    }
  };

  return (
    <Box component="div" className={styles['users-table-container-information']}>
      {!matchingLoaded && <LoadingThreeDots left />}
      {matchingLoaded && user && (
        <>
          <div>
            <div className={styles['users-table-container-information-header']}>
              <button
                disabled={selectedFlatRows.length < 1}
                className={styles['users-table-container-information-header-chevron-buttons']}
                onClick={() => updateDisplaySelected('down')}>
                <ChevronLeftRoundedIcon />
              </button>
              {`${user?.firstName || ''} ${user?.lastName || ''}`}
              <button
                disabled={selectedFlatRows.length < 1}
                className={styles['users-table-container-information-header-chevron-buttons']}
                onClick={() => updateDisplaySelected('up')}>
                <ChevronRightRoundedIcon />
              </button>
            </div>
          </div>
          {!isLeadsView && <UserDetails user={user} />}
        </>
      )}
    </Box>
  );
}

export default UserOverview;

import { CellProps, Column } from 'react-table';

import LeadStatusList from './LeadStatusList';
import { getStatusFilters } from './_functionsDisplayLeads';
import * as TypesLeads from 'types/leads';
import RowCellFavoriteButton from './RowCellFavoriteButton';
import { getFormattedCreateTime, sortByDueDate } from '../_functionsLeads';
import RowFindCompetenceBtn from './RowFindCompetenceBtn';
import { LeadDueDate } from './LeadDueDate';

export const Columns: Column<TypesLeads.Lead>[] = [
  {
    Header: 'Favorite',
    id: 'favorite',
    accessor: 'isFavorite',
    sortType: (a: any, b: any) => {
      if (a.original.isFavorite > b.original.isFavorite) return -1;
      if (b.original.isFavorite > a.original.isFavorite) return 1;
      return 0;
    },
    Cell: ({ row }: CellProps<TypesLeads.Lead>) => {
      return <RowCellFavoriteButton row={row} />;
    }
  },
  {
    Header: 'Consultant Broker',
    id: 'companyName',
    accessor: 'companyName'
  },
  {
    Header: 'Role',
    id: 'role',
    accessor: 'role'
  },
  {
    Header: 'Location',
    id: 'location',
    accessor: 'location'
  },
  {
    Header: 'Skills',
    id: 'skills',
    accessor: (lead: TypesLeads.Lead) =>
      lead.tags?.map((tag: TypesLeads.Tag) => tag.tagName).join(', ')
  },
  {
    Header: 'Due date',
    id: 'lastApplicationDate',
    accessor: (lead: TypesLeads.Lead) => <LeadDueDate dueDate={lead.lastApplicationDate} />,
    sortType: sortByDueDate
  },
  {
    Header: 'Created',
    id: 'createTime',
    accessor: (lead: TypesLeads.Lead) => getFormattedCreateTime(lead.createTime)
  },
  {
    Header: 'Status',
    id: 'status',
    accessor: (lead: TypesLeads.Lead) => (
      <LeadStatusList lead={lead} style={{ justifyContent: 'flex-end' }} />
    ),
    filter: getStatusFilters,
    sortType: (a: any, b: any) => {
      if (a.original.answered.value > b.original.answered.value) return -1;
      if (b.original.answered.value > a.original.answered.value) return 1;
      return 0;
    }
  },
  {
    Header: ' ',
    id: 'findCompetenceIcon',
    accessor: (lead: TypesLeads.Lead) => <RowFindCompetenceBtn lead={lead} />
  }
];

import { useEffect, useState, useRef } from 'react';
import Box from 'components/layouts/box/Box';
import ViewAllLess from 'components/shared/view-all-less/ViewAllLess';
import * as TypesLeads from 'types/leads';
import styles from './_description.module.css';

interface Props {
  lead?: TypesLeads.Lead;
}

function DescriptionContent(props: Props) {
  const { lead } = props;
  const PREVIEW_DESCRIPTION_HEIGHT = 480;
  const [shouldDisplayFullDescription, setShouldDisplayFullDescription] = useState<boolean>(false);
  const [isDescriptionOverflowing, setIsDescriptionOverflowing] = useState<boolean>(false);
  const [descriptionContainerHeight, setDescriptionContainerHeight] = useState<number>(
    PREVIEW_DESCRIPTION_HEIGHT
  );
  const [descriptionParagraphHeight, setDescriptionParagraphHeight] = useState<number>(0);
  const [descriptionText, setDescriptionText] = useState<string | undefined>(
    lead?.description?.replace(/^.*_.*$/gm, '').trim()
  );
  const descriptionParagraphRef = useRef<HTMLParagraphElement>(null);
  const descriptionContainerRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    if (descriptionText?.length) {
      const descriptionParagraphElement = descriptionParagraphRef.current;
      const descriptionContainerElement = descriptionContainerRef.current;
      if (descriptionParagraphElement && descriptionContainerElement) {
        const paragraphHeight = descriptionParagraphElement.clientHeight;
        const containerHeight = descriptionContainerElement.clientHeight;
        const isDescriptionOverflowing = paragraphHeight > containerHeight;
        setIsDescriptionOverflowing(isDescriptionOverflowing);
        setDescriptionParagraphHeight(paragraphHeight);
        if (!isDescriptionOverflowing) {
          setDescriptionContainerHeight(paragraphHeight + 20);
        }
      }
    } else {
      setDescriptionText('No data');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [descriptionText, descriptionParagraphRef.current]);

  useEffect(() => {
    if (shouldDisplayFullDescription) {
      setDescriptionContainerHeight(descriptionParagraphHeight + 20);
    } else {
      setDescriptionContainerHeight(PREVIEW_DESCRIPTION_HEIGHT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldDisplayFullDescription]);

  const MakeURLClickable = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text?.split(urlRegex).map((part, i) => {
      if (part.match(urlRegex)) {
        return (
          <a href={part} key={i}>
            {part}
          </a>
        );
      }
      return part;
    });
  };

  return (
    <Box>
      <h3 className={styles['description-content-title']}>Description</h3>
      <div
        ref={descriptionContainerRef}
        style={{ height: `${descriptionContainerHeight}px`, overflow: 'hidden' }}>
        <p
          className={
            shouldDisplayFullDescription
              ? styles['description-content-text']
              : styles['description-content-blur']
          }
          ref={descriptionParagraphRef}>
          {MakeURLClickable(descriptionText)}
        </p>
      </div>
      <ViewAllLess
        shouldDisplayAll={shouldDisplayFullDescription}
        isTextOverLimit={isDescriptionOverflowing}
        isText
        onClick={() => setShouldDisplayFullDescription(!shouldDisplayFullDescription)}
      />
    </Box>
  );
}

export default DescriptionContent;

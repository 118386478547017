import { SelectChangeEvent, Box, FormControl, Select, MenuItem, InputLabel } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import { FilterState } from 'redux/filterReducer';
import { RootState } from 'redux/store';

type Props = {
  allLocations: string[];
  filters: FilterState;
  setConsultantsLocationFilter: (val: string) => void;
  setLeadsLocationFilter: (val: string) => void;
};

const LocationFilter = (props: Props) => {
  const { filters, setConsultantsLocationFilter, setLeadsLocationFilter } = props;

  const handleChange = (event: SelectChangeEvent) => {
    const location = event.target.value;
    setConsultantsLocationFilter(location);

    if (location === 'Småland') {
      setLeadsLocationFilter('Växjö')
    } else if (location === 'Karlskrona/Karlshamn') {
      setLeadsLocationFilter('Karlskrona')
    } else if (location === 'All') {
      setLeadsLocationFilter('')
    } else {
      setLeadsLocationFilter(location)
    }
  };

  return (
    <Box sx={{ minWidth: 180, width: '30%' }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Location</InputLabel>
        <Select
          labelId='demo-simple-select-label'
          label='Locationn'
          sx={{ backgroundColor: 'white', height: '40px', fontFamily: 'OpenSansRegular' }}
          value={filters.consultantsLocationFilter?.toString()}
          onChange={handleChange}>
          <MenuItem value={'All'}>All locations</MenuItem>
          <MenuItem value={'Malmö'}>Malmö</MenuItem>
          <MenuItem value={'Karlskrona'}>Karlskrona</MenuItem>
          <MenuItem value={'Småland'}>Småland</MenuItem>
          <MenuItem value={'Karlshamn'}>Karlshamn</MenuItem>
          <MenuItem value={'Stockholm'}>Stockholm</MenuItem>
          <MenuItem value={'Luleå'}>Luleå</MenuItem>
          <MenuItem value={'Uppsala'}>Uppsala</MenuItem>
          <MenuItem value={'Sarajevo'}>Sarajevo</MenuItem>
          <MenuItem value={'Karlskrona/Karlshamn'}>Karlskrona/Karlshamn</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

const mapStateToProps = (state: RootState) => ({
  filters: state.filters
});

export default connect(mapStateToProps)(LocationFilter);
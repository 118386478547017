import { useEffect, useMemo } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable
} from 'react-table';
import { Paper, TableContainer } from '@mui/material';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

import usePluginRowSelect from './leads-table/RowSelection';
import ActionBar from './action-bar/ActionBar';
import LeadsTable from './leads-table/LeadsTable';
import Pagination from 'components/shared/pagination/Pagination';

import { Columns } from './leads-table/Columns';
import useWindowHeight from 'components/shared/hooks/WindowHeight';
import { FilterState } from 'redux/filterReducer';
import { RootState } from 'redux/store';
import { getTableData } from './_functionsLeads';
import * as TypesLeads from 'types/leads';

import styles from './_leads.module.css';

import { setLeadPageIndex } from 'redux/navigationReducer';
import { getLeadsTablePageSize, leadsTableReducer } from './leads-table/_functionsDisplayLeads';
import CopyToClipboardButton from 'components/shared/buttons/copy-to-clipboard-button/CopyToClipboardButton';

interface Props {
  error?: FetchBaseQueryError | SerializedError;
  isLoading: boolean;
  leads: TypesLeads.Lead[];
  locations: string[];
  favorites: TypesLeads.Favorite[];
  filters: FilterState;
}

function LeadsContent(props: Props) {
  const { leads, locations, favorites, filters, error, isLoading } = props;
  const { user: loggedInUser } = useSelector((state: RootState) => state.auth);
  const { leadPageIndex } = useSelector((state: RootState) => state.navigation);
  const dispatch = useDispatch();

  const data = useMemo(
    () => getTableData(leads, favorites, loggedInUser?.userId),
    [leads, favorites, loggedInUser?.userId]
  );
  const windowHeight = useWindowHeight();

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    setGlobalFilter,
    setFilter,
    headerGroups,
    selectedFlatRows,
    canPreviousPage,
    canNextPage,
    page,
    pageOptions,
    pageCount,
    nextPage,
    previousPage,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable<TypesLeads.Lead>(
    {
      columns: Columns,
      data,
      stateReducer: leadsTableReducer,
      initialState: {
        filters: [
          {
            id: 'status',
            value: filters
          }
        ],
        pageIndex: leadPageIndex,
        pageSize: getLeadsTablePageSize(windowHeight)
      },
      autoResetPage: false,
      autoResetSortBy: false
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    usePluginRowSelect()
  );

  useEffect(() => {
    setPageSize(getLeadsTablePageSize(windowHeight));
  }, [setPageSize, windowHeight]);

  useEffect(() => {
    setFilter('status', filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filters.searchAnswered,
    filters.searchResult,
    filters.searchRelevant,
    filters.searchFavorite,
    filters.searchAssigned
  ]);

  useEffect(() => {
    setGlobalFilter(filters.searchText);
  }, [filters.searchText, setGlobalFilter]);

  useEffect(() => {
    if (pageIndex >= pageCount && pageCount !== 0) {
      gotoPage(Math.max(0, pageCount - 1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageCount]);

  useEffect(() => {
    dispatch(setLeadPageIndex(pageIndex));
  }, [dispatch, pageIndex]);

  const paginationData: TypesLeads.Pagination = {
    pageIndex: pageIndex,
    pageOptionsLength: pageOptions.length,
    nextPage: nextPage,
    previousPage: previousPage,
    gotoPage: gotoPage,
    pageCount: pageCount,
    canNextPage: canNextPage,
    canPreviousPage: canPreviousPage
  };

  return (
    <>
      <ActionBar selectedLeads={selectedFlatRows} locations={locations} />
      <TableContainer component={Paper} className={styles['leads-table-container']}>
        <LeadsTable
          error={error}
          isLoading={isLoading}
          headerGroups={headerGroups}
          rows={page}
          getTableProps={getTableProps}
          getTableBodyProps={getTableBodyProps}
          prepareRow={prepareRow}
          pageSize={pageSize}
        />

        <div className={styles['leads-table-footer']}>
          <Pagination
            pageIndex={paginationData.pageIndex}
            pageOptionsLength={paginationData.pageOptionsLength}
            nextPage={paginationData.nextPage}
            previousPage={paginationData.previousPage}
            gotoPage={paginationData.gotoPage}
            pageCount={paginationData.pageCount}
            canNextPage={paginationData.canNextPage}
            canPreviousPage={paginationData.canPreviousPage}
          />
        </div>
      </TableContainer>
      <div className={styles['leads-mail-wrapper']}>
        <span className={styles['leads-mail-text']}>
          You can forward mails to:
          <CopyToClipboardButton text="stream.autoingest@softhouse.se" />
          if you want to add them to Neuron
        </span>
      </div>
    </>
  );
}

const mapStateToProps = (state: { filters: FilterState }) => ({
  filters: state.filters
});

export default connect(mapStateToProps)(LeadsContent);

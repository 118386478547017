import React, { useCallback } from 'react';
import { Box, Button } from '@mui/material';

import * as TypesLeads from 'types/leads';

import styles from './_leadsForUser.module.css';

import LeadsForUserSkills from './LeadsForUserSkills';
import { LeadsForUserAvailability } from '../users/availability-graph/UserAvailability';
import { getAvailabilityTitle } from '../users/display-users/_functionsDisplayUsers';
import { useNavigate } from 'react-router-dom';

interface Props {
  lead?: TypesLeads.Lead;
}

function LeadsForUserDetails(props: Props) {
  const { lead } = props;

  const isMatching = true;
  const navigate = useNavigate();

  const displaySkills = useCallback(
    () => <LeadsForUserSkills lead={lead} isMatching={isMatching} />,
    [isMatching, lead]
  );

  if (lead == null)
    return <Box component="div" className={styles['users-table-container-information']} />;

  return (
    <Box component="div" className={styles['users-table-container-information']}>
      <div className={styles['users-table-container-information-profile']}>
        <div className={styles['users-table-container-information-profile-information']}>
          {/* <Avatar
            src={lead?.imageUrl}
            className={styles['users-table-container-information-profile-image']}
          /> */}
          <div className={styles['users-table-container-information-profile-section']}>
            <span className={styles['users-table-container-information-profile-section-name']}>{`${
              lead?.role ?? ''
            }`}</span>
            <span className={styles['users-table-container-information-profile-section-title']}>
              {lead?.companyName}
            </span>
            {/* {user?.cinodeUrl ? (
              <a
                target="_blank"
                rel="noreferrer"
                href={user?.cinodeUrl}
                className={styles['users-table-container-information-profile-section-cinode']}>
                {'View on '}
                <svg
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 120 32"
                  style={{ height: '100%' }}>
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19.0385 15.9814c0 2.1021-1.7054 3.8075-3.8086 3.8075-2.103 0-3.8078-1.7054-3.8078-3.8075 0-2.1034 1.7048-3.808 3.8078-3.808 2.1032 0 3.8086 1.7046 3.8086 3.808zm-3.808 11.4222c-6.30928 0-11.42291-5.1134-11.42291-11.4222 0-6.30921 5.11363-11.42348 11.42291-11.42348 5.4331 0 9.9699 3.79622 11.1276 8.87868h-3.9564c-1.0485-2.9527-3.8593-5.07076-7.1718-5.07076-4.2054 0-7.6152 3.40966-7.6152 7.61556 0 4.2064 3.4098 7.616 7.6152 7.616 3.6185 0 6.6408-2.5249 7.4178-5.9074h3.863c-.8261 5.4961-5.5533 9.7136-11.2802 9.7136zm0-26.6536C6.81887.75 0 7.56952 0 15.9814c0 8.4119 6.81887 15.2307 15.2305 15.2307 8.4114 0 15.2306-6.8188 15.2306-15.2307C30.4611 7.56952 23.6419.75 15.2305.75zM38.03 16.0119c0-4.6202 3.4932-7.77527 8.0006-7.77527 3.3129 0 5.2288 1.80277 6.2877 3.67357l-2.7493 1.3522c-.6311-1.217-1.9833-2.1862-3.5384-2.1862-2.7269 0-4.7102 2.0961-4.7102 4.9357 0 2.8395 1.9833 4.9355 4.7102 4.9355 1.5551 0 2.9073-.9464 3.5384-2.1859l2.7493 1.3295c-1.0814 1.8706-2.9748 3.6961-6.2877 3.6961-4.5074 0-8.0006-3.1551-8.0006-7.7752zM54.397 23.5166h3.2002V8.4845H54.397v15.0321zM71.3903 23.5166l-7.1667-9.8036v9.8036h-3.2004V8.4845h3.2904l6.9641 9.4431V8.4845h3.2003v15.0321h-3.0877zM88.6989 16.0119c0-2.8171-1.8027-4.9357-4.5748-4.9357-2.772 0-4.5524 2.1186-4.5524 4.9357 0 2.7947 1.7804 4.9355 4.5524 4.9355 2.7721 0 4.5748-2.1408 4.5748-4.9355zm-12.4177 0c0-4.53 3.3129-7.77527 7.8429-7.77527 4.5526 0 7.8654 3.24527 7.8654 7.77527 0 4.5301-3.3128 7.7752-7.8654 7.7752-4.53 0-7.8429-3.2451-7.8429-7.7752zM104.594 16.0119c0-2.6593-1.623-4.7101-4.7108-4.7101h-2.7267v9.3977h2.7267c2.9748 0 4.7108-2.1408 4.7108-4.6876zm-10.6379 7.5047V8.4845h5.9271c4.7108 0 7.9778 2.9975 7.9778 7.5274 0 4.5302-3.267 7.5047-7.9778 7.5047h-5.9271zM109.363 23.5166V8.4845H120v2.8173h-7.437v3.1776h7.279v2.8171h-7.279v3.403H120v2.8171h-10.637z"
                    fill="#F9950B"></path>
                </svg>
              </a>
            ) : null} */}
          </div>
        </div>
        {/* {isMatching && <AssignUser user={user} />} */}
        <Button
          color="secondary"
          onClick={() => {
            navigate({
              pathname: `/leads/${lead.leadId}`
            });
          }}>
          View Lead
        </Button>
      </div>
      <div className={styles['users-table-container-information-body']}>
        <div className={styles['users-table-container-information-body-section-skills']}>
          {displaySkills()}
        </div>
        <div className={styles['users-table-container-information-body-section']}>
          <span className={styles['users-table-container-information-body-section-title']}>
            Location
          </span>
          <span
            className={
              lead?.location
                ? styles['users-table-container-information-body-section-data']
                : styles['users-table-container-information-body-section-no-data']
            }>
            {lead?.location || 'No data'}
          </span>
        </div>
        {isMatching && !!lead?.competenceStatus?.leadId && (
          <div className={styles['users-table-container-information-body-section']}>
            <span className={styles['users-table-container-information-bdy-section-title']}>
              {getAvailabilityTitle(lead?.competenceStatus.matchWeights?.availability)}
            </span>
            <LeadsForUserAvailability lead={lead} />
          </div>
        )}
        {/* <div className={styles['users-table-container-information-body-section']}>
          <span className={styles['users-table-container-information-body-section-title']}>
            {`Current Role${activeRoles.length != 1 ? 's' : ''}`}
          </span>
          {activeRoles.length > 0 ? (
            <UserRoles roles={activeRoles} />
          ) : (
            <span className={styles['users-table-container-information-body-section-no-data']}>
              No data
            </span>
          )}
        </div> */}
        {/* <div className={styles['users-table-container-information-body-section']}>
          <span className={styles['users-table-container-information-body-section-title']}>
            {`Previous/Upcoming Roles`}
          </span>
          {inactiveRoles.length > 0 ? (
            <UserRoles roles={inactiveRoles} />
          ) : (
            <span className={styles['users-table-container-information-body-section-no-data']}>
              No data
            </span>
          )}
        </div> */}
      </div>
    </Box>
  );
}

export default LeadsForUserDetails;

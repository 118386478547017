import Typography from '@mui/material/Typography';

import styles from './_leadStatus.module.css';

interface Props {
  displayName?: string;
  color?: string;
  fontFamily?: string;
}

function LeadStatus(props: Props) {
  const { displayName, color } = props;

  return (
    <div className={styles['lead-status']}>
      <span className={styles['lead-status-circle']} style={{ backgroundColor: color }} />
      <Typography className={styles['lead-status-text']} style={{ fontFamily: 'var(--font-family-table-regular)' }} > {displayName}</Typography>
    </div >
  );
}

export default LeadStatus;

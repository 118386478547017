import React, { KeyboardEvent, useEffect, useRef, useState, ChangeEvent } from 'react';
import { TextField } from '@mui/material';

import styles from './_information.module.css';
import { FieldError } from 'react-hook-form';

interface InputProps {
  className?: string;
  placeholder?: string;
  multiline?: boolean;
  format?: string;
}

interface Props {
  value: string;
  label?: string;
  inputProps?: InputProps;
  className?: string;
  editableOnDefault?: boolean;
  isReadOnly: boolean;
  error?: FieldError;
  isDirty: boolean;
  onChange: (arg0: string) => void;
}

const InformationInput = (props: Props) => {
  const {
    value,
    inputProps = {},
    className,
    editableOnDefault = false,
    isReadOnly,
    error,
    isDirty,
    onChange
  } = props;

  const [isEditing, setIsEditing] = useState<boolean>(editableOnDefault);
  const inputRef = useRef<HTMLInputElement>();
  const wrapperRef = useRef<HTMLDivElement>(null);

  const startEdit = () => {
    if (!isReadOnly) {
      setIsEditing(true);
    }
  };

  const stopEdit = () => {
    if (!error?.message) {
      inputRef.current?.blur();
      setIsEditing(false);
    }
  };

  const handleOnKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' || event.key === 'Escape') {
      stopEdit();
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current?.focus();
    }
    if (wrapperRef.current && isEditing && !isReadOnly) {
      wrapperRef.current?.focus();
    }
  }, [isEditing, isReadOnly]);

  useEffect(() => {
    if (wrapperRef.current && !isDirty) {
      setIsEditing(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <div className={`${styles['information']} ${className}`} ref={wrapperRef}>
      {isEditing && !isReadOnly ? (
        <TextField
          defaultValue={value}
          inputRef={inputRef}
          onBlur={stopEdit}
          multiline={inputProps.multiline}
          onKeyDown={handleOnKeyDown}
          className={styles['information-input-wrapper']}
          onChange={handleOnChange}
          inputProps={{
            className: `${styles['information-text-input']}`
          }}
          helperText={error?.message}
          error={!!error?.message}
          FormHelperTextProps={{
            className: styles['information-text-error']
          }}
        />
      ) : (
        <span
          className={`${
            isReadOnly
              ? styles['information-text-element-read-only']
              : styles['information-text-element']
          } ${!value ? styles['information-text-element-no-data'] : ''}`}
          onClick={startEdit}>
          {value || 'No data'}
        </span>
      )}
    </div>
  );
};

export default InformationInput;

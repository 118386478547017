import { useSearchParams } from 'react-router-dom';

import UsersContent from 'components/features/users/display-users/UsersContent';
import PageLayout from 'components/layouts/PageLayout';

import { useGetAllUsersQuery } from 'redux/userApi';
import { useGetFindCompetenceForLeadsQuery } from 'redux/leadApi';
import {
  getUsersWithCompetenceForLead,
  sortUsersByMatch
} from 'components/features/users/display-users/_functionsDisplayUsers';

import * as TypesLeads from 'types/leads';
import { AvailabilityFilter, FilterState } from 'redux/filterReducer';
import { connect } from 'react-redux';
import { skipToken } from '@reduxjs/toolkit/dist/query';

interface Props {
  filters: FilterState;
}

const Users = ({ filters }: Props) => {
  const [searchParams] = useSearchParams();
  const forLead = searchParams.get('forLead');
  const hiddenColumns = forLead ? ['assigned', 'location'] : ['relevance'];

  const { data: fetchedUsers = [], isError, isLoading: isLoadingUsers } = useGetAllUsersQuery();
  const { data: usersWithCompetenceForLead, isLoading: isLoadingCompetencesForLead } =
    useGetFindCompetenceForLeadsQuery(forLead ?? skipToken);

  const isLoading = isLoadingUsers || isLoadingCompetencesForLead;

  const maybeFilterByAvailability = (users: TypesLeads.User[]) => {
    if (
      forLead &&
      filters.availabilityFilter &&
      filters.availabilityFilter != AvailabilityFilter.All
    ) {
      let minAvailability;
      switch (filters.availabilityFilter) {
        case AvailabilityFilter.FullyAvailable:
          minAvailability = 1;
          break;
        case AvailabilityFilter.PartlyAvailable:
          minAvailability = 0.9;
          break;
      }
      return users.filter(
        (user) => (user.competenceStatus?.matchWeights?.availability ?? 0) >= minAvailability
      );
    }
    return users;
  };

  const maybeFilterByAssigned = (users: TypesLeads.User[]) => {
    if (filters.assignedFilter && filters.assignedFilter != AvailabilityFilter.All) {
      return users.filter((user) => {
        switch (filters.assignedFilter) {
          case AvailabilityFilter.FullyAvailable:
            return user.assigned <= 0;
          case AvailabilityFilter.PartlyAvailable:
            return user.assigned > 0 && user.assigned <= 99;
          default:
            return true;
        }
      });
    }
    return users;
  };

  const maybeFilterByLocation = (users: TypesLeads.User[]) => {
    if (filters.consultantsLocationFilter && filters.consultantsLocationFilter != 'All') {
      return users.filter((user) => user.location == filters.consultantsLocationFilter);
    }
    return users;
  };

  const applyFilters = (users: TypesLeads.User[]) => {
    let filteredUsers = maybeFilterByAvailability(users);
    filteredUsers = maybeFilterByAssigned(filteredUsers);
    filteredUsers = maybeFilterByLocation(filteredUsers);
    return filteredUsers;
  };

  const filterAndSortUsers = (users: TypesLeads.User[]) => {
    let filteredUsers = getUsersWithCompetenceForLead(users, usersWithCompetenceForLead?.matches);
    filteredUsers = applyFilters(filteredUsers);
    return sortUsersByMatch(filteredUsers);
  };

  const users = filterAndSortUsers(fetchedUsers);

  if (!users) {
    return (
      <PageLayout>
        <span>No data found</span>
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      <UsersContent
        users={users}
        isLoading={isLoading}
        isError={isError}
        matchingLoaded={!isLoadingCompetencesForLead}
        hiddenColumns={hiddenColumns}
        returnToMultipleSelected={!forLead}
      />
    </PageLayout>
  );
};

const mapStateToProps = (state: { filters: FilterState }) => ({
  filters: state.filters
});

export default connect(mapStateToProps)(Users);

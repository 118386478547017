import { useCallback, useEffect, useState } from 'react';
import { useGetLeadQuery } from 'redux/leadApi';
import SkillsList from './SkillsList';
import { getMatchingSkills, getMissingSkills } from './_functionsDisplayUsers';
import { getLimitedList } from 'components/shared/view-all-less/_functionsViewAllLess';
import * as TypesLeads from 'types/leads';
import styles from './_displayUsers.module.css';
import FormActionBar from 'components/features/lead-details/form-actions/FormActionBar';
import LoadingThreeDots from 'components/shared/loading/LoadingThreeDots';

interface Props {
  user?: TypesLeads.User;
  isMatching: boolean;
}

const UserSkills = (props: Props) => {
  const { user, isMatching } = props;

  const DEFAULT_SKILLS_NUMBER = 10;

  const { data: lead } = useGetLeadQuery(user?.competenceStatus?.leadId ?? '');

  // const filteredSkills = user?.tags.filter((tag) => tag.level != 0);

  const matchingSkills = getMatchingSkills(user?.tags || [], lead?.tags || []);
  const missingSkills = getMissingSkills(lead?.tags || [], user?.tags || []);
  const [shouldDisplayAllMatchingSkills, setShouldDisplayAllMatchingSkills] =
    useState<boolean>(false);
  const [shouldDisplayAllMissingSkills, setShouldDisplayAllMissingSkills] =
    useState<boolean>(false);
  const [shouldDisplayAllUserSkills, setShouldDisplayAllUserSkills] = useState<boolean>(false);
  const [isLeadPopulated, setIsLeadPopulated] = useState<boolean>(false);

  useEffect(() => {
    setShouldDisplayAllUserSkills(false);
    setShouldDisplayAllMatchingSkills(false);
    setShouldDisplayAllMissingSkills(false);
  }, [user]);

  useEffect(() => {
    if (lead) setIsLeadPopulated(true);
  }, [lead]);

  const displaySkillsIfMatching = useCallback(() => {
    return (
      <>
        <FormActionBar title="Skills" />
        <span className={styles['users-table-container-information-title']}>Matching skills</span>
        {isLeadPopulated ? (
          <SkillsList
            skills={getLimitedList(
              shouldDisplayAllMatchingSkills,
              DEFAULT_SKILLS_NUMBER,
              matchingSkills || []
            )}
            shouldDisplayAll={shouldDisplayAllMatchingSkills}
            limitNumber={DEFAULT_SKILLS_NUMBER}
            data={matchingSkills as TypesLeads.Tag[]}
            onClick={() => setShouldDisplayAllMatchingSkills(!shouldDisplayAllMatchingSkills)}
          />
        ) : (
          <LoadingThreeDots left={true} />
        )}

        <span className={styles['users-table-container-information-title']}>Missing skills</span>
        {isLeadPopulated ? (
          <SkillsList
            skills={getLimitedList(
              shouldDisplayAllMissingSkills,
              DEFAULT_SKILLS_NUMBER,
              missingSkills || []
            )}
            shouldDisplayAll={shouldDisplayAllMissingSkills}
            limitNumber={DEFAULT_SKILLS_NUMBER}
            data={missingSkills as TypesLeads.Tag[]}
            onClick={() => setShouldDisplayAllMissingSkills(!shouldDisplayAllMissingSkills)}
          />
        ) : (
          <LoadingThreeDots left={true} />
        )}
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, shouldDisplayAllMatchingSkills, shouldDisplayAllMissingSkills, lead, isLeadPopulated]);

  const displaySkillsIfNotMatching = useCallback(() => {
    return (
      <>
        <SkillsList
          skills={getLimitedList(
            shouldDisplayAllUserSkills,
            DEFAULT_SKILLS_NUMBER,
            user?.tags || []
          )}
          shouldDisplayAll={shouldDisplayAllUserSkills}
          limitNumber={DEFAULT_SKILLS_NUMBER}
          data={user?.tags as TypesLeads.Tag[]}
          onClick={() => setShouldDisplayAllUserSkills(!shouldDisplayAllUserSkills)}
        />
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, shouldDisplayAllUserSkills, lead, isLeadPopulated]);

  return <>{isMatching ? displaySkillsIfMatching() : displaySkillsIfNotMatching()}</>;
};

export default UserSkills;

import { Box } from '@mui/material';

import LeadStatus from './lead-status/LeadStatus';
import * as TypesLeads from 'types/leads';

import styles from './_displayLeads.module.css';
import React from 'react';

interface Props {
  lead: TypesLeads.Lead;
  style?: React.CSSProperties;
}

const LeadStatusList = (props: Props) => {
  const { lead, style } = props;
  return (
    <Box component="li" className={styles['lead-status-list']} style={style}>
      {lead.answered?.value ? (
        <LeadStatus
          displayName="Answered"
          color="var(--status-color-answered)"
          fontFamily={'var(--font-family-table-regular)'}
        />
      ) : (
        <LeadStatus
          displayName="Unanswered"
          color="var(--status-color-default)"
          fontFamily={'var(--font-family-table-regular)'}
        />
      )}
      {lead.result?.value === false && (
        <LeadStatus
          displayName="Lost"
          color="var(--status-color-default)"
          fontFamily={'var(--font-family-table-regular)'}
        />
      )}
      {lead.result?.value === true && (
        <LeadStatus
          displayName="Won"
          color="var(--status-color-won)"
          fontFamily={'var(--font-family-table-regular)'}
        />
      )}
    </Box>
  );
};

export default LeadStatusList;

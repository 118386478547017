import { SyntheticEvent, useState } from 'react';
import { Row } from 'react-table';
import FilledStarIcon from '@mui/icons-material/GradeRounded';
import LinedStarIcon from '@mui/icons-material/StarOutlineRounded';

import { useAddFavoriteMutation, useRemoveFavoriteMutation } from 'redux/favoriteApi';
import * as TypesLeads from 'types/leads';

import styles from './_displayLeads.module.css';

interface Props {
  row: Row<TypesLeads.Lead>;
}

function RowCellFavoriteButton(props: Props) {
  const { row } = props;

  const [addToFavorite, addToFavoriteState] = useAddFavoriteMutation();
  const [removeFavorite, removeFavoriteState] = useRemoveFavoriteMutation();
  const isLoading = addToFavoriteState.isLoading || removeFavoriteState.isLoading;

  const handleFavorite = async (row: Row<TypesLeads.Lead>) => {
    const leadId = row.original.leadId;
    const isFavorite = row.original.isFavorite;

    if (!isFavorite) {
      try {
        await addToFavorite({ leadId: leadId }).unwrap();
      } catch (error: unknown) {
        console.error(error);
      }
    } else {
      try {
        await removeFavorite({ leadId: leadId }).unwrap();
      } catch (error: unknown) {
        console.error(error);
      }
    }
  };

  const onClick = (event: SyntheticEvent, row: Row<TypesLeads.Lead>) => {
    event.stopPropagation();
    handleFavorite(row);
  };

  return (
    <button
      className={styles['row-icon-button']}
      onClick={(event: SyntheticEvent) => onClick(event, row)}
      disabled={isLoading}>
      {row.original.isFavorite ? <FilledStarIcon /> : <LinedStarIcon />}
    </button>
  );
}

export default RowCellFavoriteButton;

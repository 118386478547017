import React from 'react';
import { getProgressValue } from '../_functionsDisplayUsers';
import * as TypesLeads from 'types/leads';
import styles from './_displayConsultantLeads.module.css';
import ConsultantSkillProgress from './ConsultantSkillProgress';
import LeadStatusList from 'components/features/leads/leads-table/LeadStatusList';
import { Link } from 'react-router-dom';
import { LeadDueDate } from 'components/features/leads/leads-table/LeadDueDate';

type Props = {
  lead: TypesLeads.Lead;
  userId: number;
};

const ConsultantLeadsRow = (props: Props) => {
  const { lead, userId } = props;

  return (
    <Link
      to={{ pathname: `/leads/${lead.leadId}` }}
      state={{ fromConsultant: true, returnLocation: 'consultants', userId }}
      className={styles['users-selected-matching-leads-row']}>
      <div className={styles['column-avatar']} />

      <p className={styles['column-companyName']}>{lead.companyName || 'N/A'}</p>

      <p className={styles['column-role']}>{lead.role || 'N/A'}</p>

      <div className={styles['column-skills']}>
        <ConsultantSkillProgress
          value={getProgressValue(lead.competenceStatus?.matchWeights?.tag)}
        />
      </div>

      <div className={styles['column-availability']}>
        <ConsultantSkillProgress
          value={getProgressValue(lead.competenceStatus?.matchWeights?.availability)}
        />
      </div>

      <span className={styles['column-location']}>{lead.location}</span>

      <div className={styles['column-dueDate']}>
        <LeadDueDate dueDate={lead.lastApplicationDate} />
      </div>

      <div className={styles['column-status']}>
        <LeadStatusList lead={lead} />
      </div>
    </Link>
  );
};

export default ConsultantLeadsRow;

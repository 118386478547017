import React from 'react';

import * as TypesLeads from 'types/leads';

import styles from './_availabilityGraph.module.css';
import { useGetLeadQuery } from 'redux/leadApi';
import {
  getAvailabilitytGraphData,
  AbsenceGraphEntry,
  AbsenceGraphData
} from './_functionsAvailabilityGraph';
import UserAvailabilityRow from './UserAvailabilityRow';
import { useGetUserQuery } from 'redux/userApi';
import { skipToken } from '@reduxjs/toolkit/dist/query';

interface AvailabilityProps {
  user: TypesLeads.User;
  lead: TypesLeads.Lead;
  focusUser: boolean;
}

const Availability = (props: AvailabilityProps) => {
  const { user, lead, focusUser } = props;

  const competenceStatus = focusUser ? user.competenceStatus : lead.competenceStatus;

  if (!competenceStatus?.absences) {
    return <></>;
  }

  const leadStart = lead.startDate ? Date.parse(lead.startDate) : undefined;
  const leadEnd = lead.endDate ? Date.parse(lead.endDate) : undefined;

  const graphData: AbsenceGraphData = getAvailabilitytGraphData(
    competenceStatus.absences,
    leadStart,
    leadEnd
  );

  const { entries } = graphData;

  return (
    <div className={styles['availability-timeline-container']}>
      {entries?.map((absence: AbsenceGraphEntry, index: number) => {
        const { start, end, extentPercentage } = absence;

        return (
          <UserAvailabilityRow
            key={`graph-absence-${index}`}
            type="absence"
            start={start}
            end={end}
            tooltipTitle={`Occupied ${extentPercentage}%`}
            graphData={graphData}
          />
        );
      })}

      {!!leadStart && !!leadEnd && (
        <UserAvailabilityRow
          type="lead"
          start={leadStart}
          end={leadEnd}
          tooltipTitle="Lead Period"
          graphData={graphData}
        />
      )}
    </div>
  );
};

interface UserAvailabilityProps {
  user: TypesLeads.User;
}

export const UserAvailability = (props: UserAvailabilityProps) => {
  const { user } = props;
  const { data: lead } = useGetLeadQuery(user.competenceStatus?.leadId ?? skipToken);

  if (!user.competenceStatus?.leadId) {
    return <></>;
  }

  if (!lead) {
    return <></>;
  }

  return <Availability user={user} lead={lead} focusUser={true} />;
};

interface LeadsForUserAvailabilityProps {
  lead: TypesLeads.Lead;
}

export const LeadsForUserAvailability = (props: LeadsForUserAvailabilityProps) => {
  const { lead } = props;
  const { data: user } = useGetUserQuery(lead.competenceStatus?.userId ?? skipToken);

  if (!lead.competenceStatus?.userId) {
    return <></>;
  }

  if (!user) {
    return <></>;
  }

  return <Availability user={user} lead={lead} focusUser={false} />;
};

export default UserAvailability;
